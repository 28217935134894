import React from 'react'
import { Box, Toolbar, AppBar, Typography } from '@mui/material'

export default function Topbar() {

    return <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    with MazeYard
                </Typography>
            </Toolbar>
        </AppBar>
    </Box>
}